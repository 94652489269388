.l-error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 100vh;
}

/* up-to-medium,max-width-1023px */
@media (max-width: 63.9375rem) {
	.l-error__container {
		padding: 0 var(--space-20--px);
	}
}

.l-error__container {
	max-width: 22.5rem;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.l-error__container {
		max-width: 25rem;
	}
}

/* #region Logo */
.l-error__header__logo svg {
	display: block;
	width: 8.375rem;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.l-error__header__logo svg {
		width: 11.1875rem;
	}
}
/* #endregion Logo */

/* #region Title */
.l-error__header__title {
	margin-top: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.l-error__header__title {
		margin-top: var(--space-40--rem);
	}
}

.l-error__header__title--red {
	color: var(--color-red);
}

.l-error__header__title--green {
	color: var(--color-green);
}

.l-error__header__title--purple {
	color: var(--color-purple);
}
/* #endregion Title */

/* #region Main */
.l-error__main {
	margin-top: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.l-error__main {
		margin-top: var(--space-40--rem);
	}
}
/* #endregion Main */
