.teaser-z {
	background-color: var(--color-grey-super-light);
	display: flex;
	font-size: 1rem;
	padding: var(--space-16--px) var(--space-15--px);
	align-items: center;
}

.teaser-z__image {
	background-color: var(--color-white);
	border: 5px solid var(--color-grey-super-light);
	border-radius: 999999px;
	display: block;
	margin-inline-end: var(--space-15--px);
	width: 88px;
	height: 88px;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		width: 64px;
		height: 64px;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		width: 90px;
		height: 90px;
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		width: 130px;
		height: 130px;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		width: 114px;
		height: 114px;
	}
}

.teaser-z__info {
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	hyphens: auto;
	line-height: 1.2;
	text-wrap: balance;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

.teaser-z__image__info__meta button {
	-mrh-resets: button;
	color: var(--color-black);
	display: block;
	font-size: 0.875rem;
	margin-top: 4px;
	text-decoration: underline;
	text-transform: lowercase;
}

.teaser-z__image__info__meta button:is(:hover, :focus, :active) {
	color: var(--color-purple);
}
