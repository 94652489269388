.program-detail {
}

.program-detail__list {
	margin: 0 auto;
	padding-block: var(--space-30--rem);
	scroll-margin-top: var(--space-30--rem);
	width: 302px;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		width: 498px;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		width: 700px;
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		padding-block: var(--space-60--rem);
		scroll-margin-top: var(--space-60--rem);
		width: 988px;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		width: 1340px;
	}
}

.program-detail__list__title {
	margin-block: var(--space-30--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		margin-block: 2.8125rem;
	}
}

.program-detail__divider {
	background-color: var(--color-grey-light);
	margin: 0;
	height: 1px;
}
