.filter {
	box-sizing: border-box;
	position: relative;
}

.filter:not(:first-child) {
	/* up-to-xsmall,max-width-559px */
	@media (max-width: 34.9375rem) {
		margin-block-start: var(--space-25--rem);
	}
}

.filter__label {
	text-transform: uppercase;
}

/* #region Block Button */
.filter__button {
	border: none;
	cursor: pointer;
	display: block;
	padding-block: 0.375rem;
	text-decoration: underline;
	text-transform: initial;

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		padding-block: var(--space-8--rem);
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		padding-block: var(--space-10--rem);
	}
}

.filter__button:hover,
.filter__button:focus {
	border: none;
}

.filter__button .icon {
	fill: currentColor;
	margin-inline-start: 6px;
	transform: rotate(90deg);
	transition: transform 128ms ease-out;
	width: 0.625rem;
	height: 0.625rem;

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		margin-inline-start: var(--space-8--px);
		width: 0.75rem;
		height: 0.75rem;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		margin-inline-start: var(--space-10--px);
		width: 0.875rem;
		height: 0.875rem;
	}
}
/* #endregion Block Button */

/* #region Block List */
.filter__list {
	background-color: var(--color-purple);
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
	z-index: 99;
}

.filter__list {
	display: none;
}

.filter__list.mr-menu--expanded {
	display: block;
	position: absolute;
	left: 0;
}

.filter__list__item:hover .filter__link {
	text-decoration: underline;
}

.filter__list__item[data-state="active"] .filter__link {
	font-weight: 700;
}
/* #endregion Block List */

/* #region Block Link */
.filter__link {
	color: var(--color-white);
	text-decoration: none;
}

.filter__link {
	display: block;
	padding-inline: 0.375rem;
	padding-block: 0.25rem;

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		padding-inline: var(--space-8--px);
		padding-block: 0.3125rem;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		padding-inline: var(--space-10--px);
	}
}
/* #endregion Block Link */
