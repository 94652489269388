.breadcrumb {
	display: block;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.breadcrumb__item {
	display: inline;
}

.breadcrumb__item:not(:last-child)::after {
	background-color: var(--color-black);
	content: "";
	display: inline-block;
	margin-inline-start: 10px;
	margin-inline-end: 7px;
	mask-image: url("../images/arrow-right.svg");
	mask-repeat: no-repeat;
	mask-size: 100% 100%;
	width: 0.3125rem;
	height: 0.5rem;
}

.breadcrumb__item__link {
	color: var(--color-black);
}

.breadcrumb__item__link:is(:active, :focus, :hover) {
	color: var(--hover-color);
}

.breadcrumb__item [data-is-last-item] {
	color: var(--color-grey-dark);
}
