.info-bar {
	display: grid;
	grid-row-gap: var(--space-20--rem);
	grid-template-rows: auto 1fr;
}

/* from-xsmall,min-width-560px */
@media (min-width: 35rem) {
	.info-bar {
		grid-column-gap: var(--space-20--px);
		grid-template-rows: none;
		grid-template-columns: repeat(2, 1fr);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.info-bar {
		grid-row-gap: 0;
		grid-template-columns: repeat(4, 1fr);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.info-bar {
		grid-column-gap: var(--space-40--px);
	}
}

.info-bar__opening-hours__link {
	color: var(--color-purple);
	text-decoration: none;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.info-bar__name,
	.info-bar__opening-hours {
		max-width: 13.125rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.info-bar__name,
	.info-bar__opening-hours {
		max-width: 14.6875rem;
	}
}
