.sidebar-meta {
}

.sidebar-meta__tags {
	border-bottom: var(--space-1--px) solid var(--color-grey-super-light);
	margin-bottom: var(--space-20--rem);
	padding-bottom: var(--space-20--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.sidebar-meta__tags {
		margin-bottom: var(--space-30--rem);
		padding-bottom: var(--space-30--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.sidebar-meta__tags {
		margin-bottom: var(--space-40--rem);
		padding-bottom: var(--space-40--rem);
	}
}

.sidebar-meta__tags__link {
	color: currentColor;
	text-decoration: none;
	text-transform: uppercase;
}

.sidebar-meta__tags__link:is(:active, :focus, :hover) {
	color: var(--hover-color);
}

.sidebar-meta__info dl,
.sidebar-meta__info dd {
	margin: 0;
}

.sidebar-meta__info dt,
.sidebar-meta__info dd {
	display: inline;
}

.sidebar-meta__info a {
	text-decoration: none;
}
