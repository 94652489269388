.partner-grid {
	background-color: var(--color-grey-super-light);
	padding-block: var(--space-25--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.partner-grid {
		padding-block: var(--space-40--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.partner-grid {
		padding-block: var(--space-60--rem);
	}
}

.partner-grid__list {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	row-gap: var(--space-20--rem);
	column-gap: var(--space-20--px);
	list-style: none;
	margin: 0 auto;
	padding: 0;
	justify-content: center;
	max-width: 15.625rem;
}

/* from-xsmall,min-width-560px */
@media (min-width: 35rem) {
	.partner-grid__list {
		max-width: 25.625rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.partner-grid__list {
		max-width: 100%;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.partner-grid__list {
		row-gap: var(--space-40--rem);
		column-gap: var(--space-40--px);
	}
}

.partner-grid__list__item {
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	align-items: center;
	width: 7.1875rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.partner-grid__list__item {
		width: 10rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.partner-grid__list__item {
		width: 11rem;
	}
}
