.pagination {
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0 auto;
	padding: 0;
	justify-content: center;
	align-items: center;
}

.pagination__item {
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 128ms ease-in-out;
	height: calc((26px + 26px + 1.625rem) / 3);

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		height: calc((30px + 30px + 1.875rem) / 3);
	}
}

.pagination__item:not([data-state="prev"], [data-state="next"]) {
	min-width: calc((26px + 26px + 1.625rem) / 3);

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		min-width: calc((30px + 30px + 1.875rem) / 3);
	}
}

.pagination__item:hover {
	background-color: var(--color-grey-super-light);
}

.pagination__item:hover .pagination__item__link {
	text-decoration: none;
}

.pagination__item[data-state="current"] {
	color: var(--hover-color);
}

.pagination__item__link {
	color: currentColor;
	text-align: center;
	text-decoration: underline;
	width: 100%;
}

.pagination__item__link,
.pagination__item[data-state="current"],
.pagination__item[data-state="dots"] {
	box-sizing: border-box;
	padding-inline: 6px;
}

.pagination__item[data-state="prev"] {
	margin-inline-end: 6px;

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		margin-inline-end: 8px;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		margin-inline-end: 10px;
	}
}

.pagination__item[data-state="next"] {
	margin-inline-start: 6px;

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		margin-inline-start: 8px;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		margin-inline-start: 10px;
	}
}
