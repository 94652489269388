* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: var(--font-sans);
	font-size: 0.875rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	body {
		font-size: 1rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	body {
		font-size: 1.125rem;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ul:not([class]),
ol:not([class]) {
	font-family: var(--font-sans);
	margin-top: var(--space-15--rem);
	margin-bottom: var(--space-15--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	blockquote,
	ul:not([class]),
	ol:not([class]) {
		margin-top: var(--space-20--rem);
		margin-bottom: var(--space-20--rem);
	}
}

:is(h1, h2, h3, h4, h5, h6, p, blockquote):first-child {
	margin-top: 0;
}

:is(ul, ol):not([class]):first-child {
	margin-top: 0;
}

:is(h1, h2, h3, h4, h5, h6, p, blockquote):last-child {
	margin-bottom: 0;
}

:is(ul, ol):not([class]):last-child {
	margin-bottom: 0;
}

:is(h1, h2, h3, h4, h5, h6, p, blockquote):only-child {
	margin-top: 0;
	margin-bottom: 0;
}

:is(ul, ol):not([class]):only-child {
	margin-top: 0;
	margin-bottom: 0;
}

/* #region Type-A */
.type-a,
p,
ul,
ol,
h3,
h4,
label {
	font-size: 1rem /* 16px */;
	font-style: normal;
	font-weight: 400;
	line-height: 1.3125; /* 21px */
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.type-a,
	p,
	ul,
	ol,
	h3,
	h4,
	label {
		font-size: 1.125rem; /* 18px */
		line-height: calc(24 / 18); /* 24px */
	}
}

h3 {
	font-weight: 700;
	text-transform: uppercase;
}

h4 {
	color: var(--color-grey-dark);
	font-weight: 700;
	text-transform: uppercase;
}

.type-a b,
.type-a strong {
	font-weight: 700;
}
/* #endregion Type-A */

/* #region Type-B */
.type-b,
h2 {
	font-size: 1.25rem; /* 20px */
	font-style: normal;
	font-weight: 400;
	line-height: calc(24 / 20); /* 24px */
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.type-b,
	h2 {
		font-size: 1.375rem; /* 22px */
		line-height: calc(27 / 22); /* 27px */
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.type-b,
	h2 {
		font-size: 1.5rem; /* 24px */
		line-height: calc(29 / 24); /* 29px */
	}
}
/* #endregion Type-B */

/* #region Type-C */
.type-c {
	font-size: 1.375rem; /* 22px */
	font-style: normal;
	font-weight: 400;
	line-height: calc(26 / 22); /* 26px */
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.type-c {
		font-size: 1.75rem; /* 28px */
		line-height: calc(33 / 28); /* 33px */
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.type-c {
		font-size: 2rem; /* 32px */
		line-height: calc(38 / 32); /* 38px */
	}
}
/* #endregion Type-C */

/* #region Type-D */
.type-d,
blockquote > cite,
blockquote > p > cite {
	font-size: 0.75rem; /* 12px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.3333; /* 16px */
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.type-d,
	blockquote > cite,
	blockquote > p > cite {
		font-size: 0.875rem; /* 14px */
		line-height: 1.2857; /* 18px */
	}
}
/* #endregion Type-D */

/* #region Type-E */
.type-e,
input,
select {
	font-size: 1rem; /* 16px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.5; /* 24px */
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.type-e,
	input,
	select {
		font-size: 1.125rem; /* 18px */
		line-height: 1.5556; /* 28px */
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.type-e,
	input,
	select {
		font-size: 1.25rem; /* 20px */
		line-height: 1.5; /* 30px */
	}
}
/* #endregion Type-E */

/* #region Type-F */
.type-f {
	font-size: 0.875rem; /* 14px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.5714; /* 22px */
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.type-f {
		font-size: 0.9375rem; /* 15px */
		line-height: 1.4667; /* 22px */
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.type-f {
		font-size: 1rem; /* 16px */
		line-height: 1.5; /* 24px */
	}
}
/* #endregion Type-F */

/* #region Type-G */
.type-g,
h1 {
	font-size: 1.75rem; /* 28px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.1429; /* 32px */
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.type-g,
	h1 {
		font-size: 2rem; /* 32px */
		line-height: 1.0625; /* 34px */
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.type-g,
	h1 {
		font-size: 2.25rem; /* 36px */
		line-height: 1.0556; /* 38px */
	}
}
/* #endregion Type-G */

/* #region Type-I */
.type-i,
blockquote,
blockquote > p {
	font-family: var(--font-sans);
	font-size: 1.125rem; /* 18px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.3333; /* 24px */
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.type-i,
	blockquote,
	blockquote > p {
		font-size: 1.25rem; /* 20px */
		line-height: 1.35; /* 27px */
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.type-i,
	blockquote,
	blockquote > p {
		font-size: 1.375rem; /* 22px */
		line-height: 1.3636; /* 30px */
	}
}
/* #endregion Type-I */

h5 {
	font-size: 0.875rem;
	line-height: normal;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	h5 {
		font-size: 1rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	h5 {
		font-size: 1.25rem;
	}
}

h6 {
	font-size: 0.875rem;
	line-height: normal;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	h6 {
		font-size: 1rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	h6 {
		font-size: 1.125rem;
	}
}

i,
em,
blockquote {
	font-style: italic;
}

blockquote {
	margin-right: var(--space-60--rem);
	margin-left: var(--space-30--rem);
}

blockquote > p {
	margin-top: var(--space-20--rem);
	margin-bottom: var(--space-20--rem);
}

blockquote > cite::before,
blockquote > p > cite::before {
	content: "- ";
}
