.primary-navigation {
	display: flex;
	flex-direction: row;
	line-height: 1;
	list-style: none;
	margin: 0;
	padding: 0;
}

.primary-navigation__item:not(:last-child) {
	margin-right: var(--space-40--px);
}

.primary-navigation__item--current .primary-navigation__link {
	color: var(--hover-color);
}

.primary-navigation__item:not(:last-child) {
	margin-right: var(--space-60--px);
}

.primary-navigation__link {
	color: var(--color-black);
	text-decoration: none;
}

.primary-navigation__link:hover {
	opacity: 0.8;
}
