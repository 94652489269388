.teaser-list-f {
	display: grid;
	grid-row-gap: var(--space-40--rem);

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		grid-row-gap: var(--space-60--rem);
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		grid-row-gap: var(--space-90--rem);
	}
}

.teaser-list-f__teaser {
	color: var(--color-black);
	display: flex;
	flex-direction: column-reverse;
	text-decoration: none;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		display: grid;
		grid-column-gap: var(--column-gap);
		grid-template-columns: repeat(2, 1fr);
	}
}

.teaser-list-f__data {
	order: 2;
}

.teaser-list-f__teaser[data-image-position="right"] .teaser-list-f__data {
	order: 1;
}

.teaser-list-f__data__title,
.teaser-list-f__data__subtitle {
	margin: 0;
}

.teaser-list-f__data__title {
	color: var(--color-purple);
	text-transform: none;
}

.teaser-list-f__data__subtitle {
	margin-bottom: var(--space-10--rem);
	text-transform: uppercase;
}

/* #region Meta */
.teaser-list-f__meta {
	display: flex;
	flex-flow: column;

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		flex-flow: row;
	}
}

.teaser-list-f__meta__date {
	color: var(--color-red);
	margin: 0;
	margin-inline-end: var(--space-10--px);
}

.teaser-list-f__meta__duration {
	color: var(--color-green);
	margin: 0;
}
/* #endregion Meta */

.teaser-list-f__data__excerpt {
	margin-block-start: var(--space-20--rem);

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		margin-block-start: var(--space-40--rem);
	}
}

.teaser-list-f__data__author {
	color: var(--color-grey-dark);
	margin-block-start: var(--space-10--rem);
	margin-block-end: 0;
}

.teaser-list-f__data__button {
	margin-block-start: var(--space-20--rem);

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		margin-block-start: var(--space-30--rem);
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		margin-block-start: var(--space-40--rem);
	}
}

.teaser-list-f__image-container {
	order: 1;
	position: relative;

	/* up-to-xsmall,max-width-559px */
	@media (max-width: 34.9375rem) {
		margin-block-end: var(--space-20--rem);
	}
}

.teaser-list-f__teaser[data-image-position="right"] .teaser-list-f__image-container {
	order: 2;
}
