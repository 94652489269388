.search-form {
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.search-form__form {
		display: flex;
	}
}

/* #region Input */
.search-form__input {
	width: 100%;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.search-form__input .form__text__input {
		padding: 0.875rem var(--space-15--px);
		height: 3.375rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.search-form__input .form__text__input {
		padding: var(--space-20--rem) var(--space-20--px);
		height: 4.375rem;
	}
}
/* #endregion Input */

/* #region Button */
.search-form__button {
	margin-top: var(--space-15--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.search-form__button {
		margin-top: 0;
		margin-left: var(--space-20--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.search-form__button {
		margin-left: var(--space-30--px);
	}
}
/* #endregion Button */
