.teaser-y {
}

.teaser-y mr-input-sink {
	cursor: pointer;
	position: relative;
}

.teaser-y__button {
	-mrh-resets: button;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.teaser-y__button:hover {
	border: none;
}

.teaser-y__item__image-container {
	margin-block-end: var(--space-20--rem);

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		margin-block-end: var(--space-30--rem);
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		margin-block-end: var(--space-40--rem);
	}
}

.teaser-y__item__image {
	width: 100%;
}

.teaser-y__item__title {
	margin: 0;
}

.teaser-y mr-input-sink:hover .teaser-y__item__title {
	color: var(--color-purple);
}

.teaser-y__item__actors {
	color: var(--color-purple);
	margin: 0;
}

.teaser-y__item__date {
	color: var(--color-red);
	margin: 0;
}
