.artworks-list {
	display: grid;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		grid-row-gap: var(--space-16--rem);
		grid-column-gap: var(--space-16--px);
		grid-template-columns: repeat(2, 1fr);
	}

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		grid-row-gap: var(--space-20--rem);
		grid-column-gap: var(--space-20--px);
		grid-template-columns: repeat(3, 1fr);
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		grid-row-gap: var(--space-40--rem);
		grid-column-gap: var(--space-40--px);
		grid-template-columns: repeat(4, 1fr);
	}
}

/* #region ShowMore */
.artworks-list__show-more {
	padding-block-start: var(--space-30--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		margin: 0 auto;
		padding-block-start: var(--space-60--rem);
		width: max-content;
	}
}

.artworks-list__show-more__button {
	text-align: center;

	/* up-to-small,max-width-767px */
	@media (max-width: 47.9375rem) {
		width: 100%;
	}
}
/* #endregion ShowMore */
