.teaser-list-d {
	display: grid;
	grid-row-gap: var(--space-25--rem);
	grid-column-gap: var(--column-gap);
	grid-template-columns: repeat(2, minmax(0, 50%));
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.teaser-list-d {
		grid-column-gap: var(--column-gap);
		grid-template-columns: repeat(4, minmax(0, 25%));
	}
}

.teaser-list-d__teaser {
	color: var(--color-black);
	text-decoration: none;
}

/* #region Image */
.teaser-list-d__image-container {
	display: block;
	margin-bottom: var(--space-20--rem);
	position: relative;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list-d__image-container {
		margin-bottom: var(--space-40--rem);
	}
}

.teaser-list-d__image-container .icon-play {
	position: absolute;
	stroke: var(--color-white);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
}

.teaser-list-d__image {
	width: 100%;
}
/* #endregion Image */

/* #region Meta */
.teaser-list-d__meta {
}

.teaser-list-d__meta__title,
.teaser-list-d__meta__date,
.teaser-list-d__meta__subtitle {
	margin: 0;
}

.teaser-list-d__meta__subtitle {
	margin-bottom: var(--space-10--rem);
	text-transform: uppercase;
}

.teaser-list-d__meta__title {
	color: var(--color-purple);
	hyphens: auto;
	text-transform: none;
}

.teaser-list-d__meta__date {
	color: var(--color-red);
}

.teaser-list-d__meta__duration {
	color: var(--color-green);
	margin: 0;
}
/* #endregion Meta */

/* #region Excerpt */
.teaser-list-d__excerpt {
	margin-top: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list-d__excerpt {
		margin-top: var(--space-40--rem);
	}
}
/* #endregion Excerpt */

/* #region Author */
.teaser-list-d__author {
	color: var(--color-grey-dark);
	margin-top: var(--space-10--rem);
}
/* #endregion Author */
