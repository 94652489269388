.teaser-list-e {
}

.teaser-list-e__item {
	padding: var(--space-25--rem) 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.teaser-list-e__item {
		padding: var(--space-30--rem) 0;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list-e__item {
		padding: var(--space-50--rem) 0;
	}
}

.teaser-list-e__item:not(:last-child) {
	border-bottom: var(--space-1--px) solid var(--color-grey-light);
}

/* #region Link */
.teaser-list-e__item__link {
	color: var(--color-black);
	text-decoration: none;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.teaser-list-e__item__link {
		display: grid;
		grid-column-gap: var(--space-100--px);
		grid-template-columns: 14.375rem auto;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list-e__item__link {
		grid-column-gap: var(--space-150--px);
		grid-template-columns: 18.75rem auto;
	}
}

.teaser-list-e__item__link:hover .teaser-list-e__item__meta__title {
	text-decoration: underline;
}
/* #endregion Link */

/* #region Image */
.teaser-list-e__item__image-container {
	position: relative;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.teaser-list-e__item__image-container {
		align-self: center;
	}
}

.teaser-list-e__item__image {
	display: none;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.teaser-list-e__item__image {
		display: block;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.teaser-list-e__item__image img {
		width: 100%;
	}
}
/* #endregion Image */

/* #region Meta */

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.teaser-list-e__item__meta {
		align-self: center;
		max-width: 30rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list-e__item__meta {
		max-width: 37.5rem;
	}
}

.teaser-list-e__item__meta__title {
	color: var(--hover-color);
	margin-bottom: var(--space-10--rem);
}

.teaser-list-e__item__meta__text {
	margin: 0;
}

.teaser-list-e__item__meta__button {
	margin-top: var(--space-10--rem);
}
/* #endregion Meta */

/* #region List Header */
.teaser-list-e .list-header {
	margin-bottom: 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.teaser-list-e .list-header {
		margin-bottom: var(--space-10--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list-e .list-header {
		margin-bottom: 0;
	}
}
/* #endregion List Header */
