button,
.button {
	-mrh-resets: button; /* button-reset */
	border: 1px solid var(--color-black);
	text-transform: uppercase;
}

/* Hover */
button:hover,
.button:hover {
	border: 1px solid var(--hover-color);
	color: var(--hover-color);
}

/* #region Button Default */
.button {
	/* Button default follows TYPE-D */
	font-size: 0.75rem; /* 12px */
	font-style: normal;
	font-weight: 400;
	line-height: 1.3333; /* 16px */
	/* Button default follows TYPE-D */

	padding: 0.375rem 0.875rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.button {
		padding: 0.5625rem var(--space-20--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.button {
		/* Button default follows TYPE-D */
		font-size: 0.875rem; /* 14px */
		line-height: 1.2857; /* 18px */
		/* Button default follows TYPE-D */

		padding: 0.875rem var(--space-30--rem);
	}
}
/* #endregion Button Default */

/* #region Button Large */
.button--large {
	/* Button large follows TYPE-A */
	font-size: 0.875rem; /* 14px */
	line-height: 1.5714; /* 22px */
	/* Button large follows TYPE-A */

	padding: 0.5625rem var(--space-20--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.button--large {
		/* Button large follows TYPE-A */
		font-size: 1rem; /* 16px */
		line-height: 1.5; /* 24px */
		/* Button large follows TYPE-A */

		padding: 0.875rem var(--space-30--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.button--large {
		/* Button large follows TYPE-A */
		font-size: 1.125rem; /* 18px */
		line-height: 1.6667; /* 30px */
		/* Button large follows TYPE-A */

		padding: 1.1875rem var(--space-40--rem);
	}
}
/* #endregion Button Large */

/* #region Filled */
.button[data-variant="filled"] {
	background-color: var(--hover-color);
	border: 1px solid var(--hover-color);
	color: var(--color-white);
	transition:
		background-color 192ms ease-in-out,
		color 192ms ease-in-out;
}

.button[data-variant="filled"]:is(:hover, :focus, :active) {
	background-color: transparent;
	color: var(--hover-color);
}
/* #endregion Filled */
