.site-header {
	background-color: var(--color-white);
	position: sticky;
	top: 0;
	z-index: var(--z-index-header);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-header {
		top: calc(var(--meta-navigation-height) * -1);
	}
}

/* #region Meta */
.site-header__meta {
	box-sizing: border-box;
	display: none;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-header__meta {
		border-bottom: var(--space-1--px) solid var(--color-grey-light);
		display: block;
	}
}

.site-header__meta__inner {
	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		display: flex;
		flex-flow: row;
		justify-content: flex-end;
		height: var(--meta-navigation-height);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-header__meta__navigation {
		padding: var(--space-10--rem) var(--space-20--px);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-header__meta__languages {
		border-left: var(--space-1--px) solid var(--color-grey-light);
		padding: var(--space-10--rem) 0 var(--space-10--rem) var(--space-20--rem);
	}
}
/* #endregion Meta */

/* #region Bottom */
.site-header__bottom {
	border-bottom: var(--space-1--px) solid var(--color-black);
}

.site-header__bottom__inner {
	box-sizing: border-box;
	display: flex;
	padding: var(--space-10--rem) 0;
	justify-content: space-between;
	align-items: center;
	height: var(--scrolled-header-height);

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		padding: var(--space-20--rem) 0;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-header__bottom--no-line {
		border-bottom: none;
	}
}
/* #endregion Bottom */

.site-header__logo__link svg {
	display: block;
	width: 8.375rem;
	height: 1.875rem;
}

/* #region Icons */
.site-header__icons {
	display: flex;
}

.site-header__icon:hover {
	border: none;
}

.site-header__icon:last-child {
	display: block;
	margin-left: var(--space-20--px);
}

.site-header__icon svg {
	display: block;
	width: 1.25rem;
	height: 1.25rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-header__icon:last-child {
		display: none;
	}
}

.site-header__icon--hamburger {
	-mrh-resets: button;
}
/* #endregion Icons */

/* #region Primary Navigation */
.site-header__primary-navigation {
	display: none;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-header__primary-navigation {
		display: block;
	}
}
/* #endregion Primary Navigation */

/* #region Sub */
.site-header__sub-navigation {
	display: none;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-header__sub-navigation {
		background-color: var(--hover-color);
		color: var(--color-white);
		display: block;
		padding: var(--space-10--rem) 0;
	}
}
/* #endregion Sub */
