.modal-dialog {
	display: none;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.modal-dialog[data-state="opening"],
.modal-dialog[data-state="open"],
.modal-dialog[data-state="closing"] {
	display: block;
	pointer-events: auto;
}

.modal-dialog:focus {
	outline: none;
}

.modal-dialog__content {
	display: block;
	opacity: 0;
	overflow-y: scroll;
	scroll-behavior: smooth;
}

.modal-dialog[data-state="opening"] .modal-dialog__content,
.modal-dialog[data-state="open"] .modal-dialog__content,
.modal-dialog[data-state="closing"] .modal-dialog__content {
	background-color: #fefefe;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	z-index: 101;
}

.modal-dialog[data-state="open"] .modal-dialog__content {
	opacity: 1;
}

.modal-dialog[data-state="opening"] .modal-dialog__background,
.modal-dialog[data-state="open"] .modal-dialog__background,
.modal-dialog[data-state="closing"] .modal-dialog__background {
	background-color: #000000aa;
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}
