.form {
	box-sizing: border-box;
	font-size: 16px;
	font-size: max(16px, 1rem);
	width: 100%;
}

.form__group {
	border: 0;
	margin: 0;
	padding: 0;
	width: 100%;
}

.form__group:not(:last-child) {
	margin-bottom: var(--space-30--rem);
}

.form__group__title {
	font-size: 1.5rem;
	font-weight: 700;
}

/* #region Form Row */
.form__row {
	margin: var(--space-15--rem) 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.form__row {
		margin: var(--space-30--rem) 0;
	}
}

/* Disabled */
.form__row--disabled .form__label {
	color: var(--color-grey-dark);
}
/* #endregion Form Row */

/* #region Form Field */
.form__field-wrapper {
	display: flex;
	flex-flow: column;
	margin: var(--space-10--rem) 0;
	align-items: flex-start;
}
/* #endregion Form Field */

/* #region Form Label */
.form__label-wrapper {
	display: flex;
	flex-flow: column;
	margin-bottom: var(--space-10--rem);
	align-items: flex-start;
}

.form__label {
	color: var(--color-grey-dark);
}
/* #endregion Form Label */

/* #region Input Types */
.form__text__input,
.form__textarea,
.form__select {
	border-radius: 0;
}

.form__date__input,
.form__select,
.form__text__input,
.form__textarea {
	border: 1px solid var(--color-grey-light);
	box-sizing: border-box;
	padding: var(--space-10--rem);
	text-transform: none;
	width: 100%;
}

.form__date__input::placeholder,
.form__select::placeholder,
.form__text__input::placeholder,
.form__textarea::placeholder {
	color: var(--color-grey-dark);
}

.form__select-wrapper {
	position: relative;
}

.form__select-wrapper::after {
	border-top: var(--space-8--rem) solid var(--color-black);
	border-right: var(--space-8--rem) solid transparent;
	border-left: var(--space-8--rem) solid transparent;
	content: "";
	display: block;
	pointer-events: none;
	position: absolute;
	top: 50%;
	right: 12px;
	transform: translateY(-50%);
	width: 0;
	height: 0;

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		right: 15px;
	}
}

.form__select {
	-webkit-appearance: none;
}

.form__textarea {
	resize: vertical;
	height: 8rem;
}

.form__file__input {
	margin-top: var(--space-15--rem);
}

.form__text__input:disabled {
	background: none;
	color: var(--color-grey-dark);
	cursor: not-allowed;
}
/* #endregion Input Types */

/* #region Checkbox + Radio */
.form__checkbox,
.form__radio {
	margin-bottom: var(--space-10--rem);
}

.form__checkbox__label,
.form__radio__label {
	line-height: 1.6;
}

.form__checkbox__input,
.form__radio__input {
	accent-color: currentColor;
	margin-inline-end: var(--space-8--px);
	vertical-align: text-bottom;
	width: var(--space-20--rem);
	height: var(--space-20--rem);
}

/* Disabled */
.form__checkbox__input:disabled + .form__checkbox__label,
.form__radio__input:disabled + .form__radio__label {
	color: var(--color-grey-dark);
	cursor: not-allowed;
}
/* #endregion Checkbox + Radio */

/* #region Form Buttons */
.form__file__input__button,
.form__submit__button {
	appearance: none;
	background-color: var(--color-foreground);
	border: none;
	color: var(--color-background);
	cursor: pointer;
	margin: 0;
	padding: var(--space-10--rem);
}
/* #endregion Form Buttons */

/* #region Warnings (Error + Optional) */
.form__error-label {
	color: var(--color-red);
	font-size: 0.875rem;
	font-style: italic;
	margin-top: var(--space-10--rem);
}

.form__row--has-error input:not(:focus) {
	border-color: var(--color-red);
}

.form__row--has-error .form__label {
	color: var(--color-red);
}

.form__label-subtext {
	color: var(--color-purple);
	font-size: 0.875rem;
	font-style: italic;
	margin-top: var(--space-10--rem);
}
/* #endregion Warnings (Error + Optional) */
