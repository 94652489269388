.video-embed {
	position: relative;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.video-embed {
		margin: 0 auto;
		max-width: 40.625rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.video-embed {
		max-width: 50rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.l-single[data-has-sidebar-navigation] .video-embed {
		max-width: 100%;
	}
}

.video-embed__video-wrapper {
	aspect-ratio: 16/9;
	display: flex;
	position: relative;
	width: 100%;
}

.video-embed__video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* region Blocked Content */
.video-embed__blocked-content {
	aspect-ratio: 16/9;
	background-color: var(--color-grey-super-light);
	color: var(--color-black);
	display: flex;
	align-items: center;
}

.video-embed__blocked-content__inner {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: var(--space-25--rem) var(--space-25--px);
	justify-content: center;
	align-items: center;
	text-align: center;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.video-embed__blocked-content__inner {
		padding: var(--space-40--rem) var(--space-40--px);
	}
}

.video-embed__blocked-content .arrow-link {
	color: var(--color-black);
	margin-top: var(--space-30--rem);
}

.video-embed__blocked-content .arrow-link:hover {
	color: var(--hover-color);
}

.video-embed__blocked-content .arrow-link .icon-arrow-right {
	fill: var(--color-black);
}

.video-embed__blocked-content .arrow-link:hover .icon-arrow-right {
	fill: var(--hover-color);
}
/* #endregion Blocked Content */
