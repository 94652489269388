.form-embed {
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.form-embed {
		margin: 0 auto;
		max-width: 40.625rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.form-embed {
		max-width: 50rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.l-single[data-has-sidebar-navigation] .form-embed {
		max-width: 100%;
	}
}
