.site-footer {
}

.site-footer hr {
	margin: 0;
}

.site-footer__row {
	padding-top: var(--space-25--rem);
	padding-bottom: var(--space-25--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-footer__row {
		padding-top: var(--space-40--rem);
		padding-bottom: var(--space-40--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-footer__row {
		padding-top: var(--space-60--rem);
		padding-bottom: var(--space-60--rem);
	}
}

.site-footer__action-blocks {
}

.site-footer__columns-wrapper {
	box-sizing: border-box;
	margin: 0 auto;
	overflow-x: auto;
	width: 100%;
	max-width: 83.75rem;
}

.site-footer__columns {
	display: grid;
	column-gap: var(--column-gap);
	grid-template-columns: repeat(4, minmax(7.8125rem, 25%));
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.site-footer__columns {
		width: 100%;
		min-width: fit-content;
	}
}

/* up-to-large,max-width-1439px */
@media (max-width: 89.9375rem) {
	.site-footer__columns {
		padding-right: var(--space-20--px);
		padding-left: var(--space-20--px);
	}
}

.site-footer__columns__column {
}

.site-footer__columns__title {
	font-weight: 700;
	margin-bottom: var(--space-10--rem);
}

.site-footer__columns__navigation {
	list-style: none;
	margin: 0;
	padding: 0;
}

.site-footer__columns__navigation__item a {
	text-decoration: none;
}

.site-footer__columns__navigation__item--current a {
	color: var(--hover-color);
}

.site-footer__columns__navigation__item:hover a {
	text-decoration: underline;
}

.site-footer__data {
	color: var(--color-grey-dark);
}

.site-footer__data__inner {
	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		display: grid;
		column-gap: var(--column-gap);
		grid-template-columns: repeat(4, minmax(0, 25%));
	}
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.site-footer__data__column:not(:last-child) {
		margin-bottom: var(--space-25--rem);
	}
}

.site-footer__data__column p {
	margin-bottom: var(--space-10--rem);
}

.site-footer__data__column[data-column="newsletter"] a {
	text-decoration: none;
}

.site-footer__data__column[data-column="newsletter"] a:hover {
	text-decoration: underline;
}

.site-footer__data__logo {
	width: 5.875rem;
	height: auto;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.site-footer__data__logo {
		width: 7.9375rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.site-footer__data__logo {
		width: 9.1875rem;
	}
}

.site-footer__data__navigation,
.site-footer__data__social-links {
	list-style: none;
	margin: 0;
	padding: 0;
}

.site-footer__data__navigation a {
	text-decoration: none;
}

.site-footer__data__navigation a:hover {
	text-decoration: underline;
}

.site-footer__data__navigation__item--current a {
	color: var(--hover-color);
}

.site-footer__data__social-links__item {
	display: inline-flex;
}

.site-footer__data__social-links__item:not(:last-child) {
	margin-right: var(--space-8--px);
}

.site-footer__data__social-links__item a {
	background-color: var(--color-grey-dark);
	mask-repeat: no-repeat;
	mask-size: 100% 100%;
	width: 1.25rem;
	height: 1.25rem;
}

.site-footer__data__social-links__item--facebook a {
	mask-image: url("../images/facebook.svg");
}

.site-footer__data__social-links__item--instagram a {
	mask-image: url("../images/instagram.svg");
}

.site-footer__data__social-links__item--twitter a {
	mask-image: url("../images/twitter.svg");
}

.site-footer__data__social-links__item:hover a {
	background-color: var(--hover-color);
}
