.language-switch {
	display: block;
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	transition: max-width 128ms ease-in;
	white-space: nowrap;
	max-width: 2.25rem;
}

.language-switch:is(:active, :focus, :focus-visible, :focus-within, :hover),
*:is(:active, :focus, :focus-visible, :focus-within, :hover) > .language-switch {
	overflow: hidden;
	max-width: 7.5rem;
}

.language-switch:has(:active, :focus-visible) {
	overflow: hidden;
	max-width: 7.5rem;
}

.language-switch__item {
	display: inline-block;
}

.language-switch__link {
	border-bottom: 0 solid transparent;
	color: var(--color-grey-dark);
	display: block;
	margin-right: var(--space-20--rem);
	text-decoration: none;
	text-transform: uppercase;
	width: auto;
}

.language-switch__link--current {
	color: var(--color-black);
}

.language-switch__link:hover,
.language-switch__link:focus {
	color: var(--color-black);
}
