.row {
	box-sizing: border-box;
	margin-top: var(--space-25--rem);
	margin-bottom: var(--space-25--rem);
	position: relative;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.row {
		margin-top: var(--space-30--rem);
		margin-bottom: var(--space-30--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.row {
		margin-top: var(--space-40--rem);
		margin-bottom: var(--space-40--rem);
	}
}

/* #region anchor scroll offsets */
.row[id] {
	scroll-margin-top: 3.5rem;
}

@media (min-width: 64rem) {
	/* from-medium,tablet-l,min-width-1024px */
	.row[id] {
		scroll-margin-top: 8.5rem;
	}
}

@media (min-width: 90rem) {
	/* from-large,min-width-1440px */
	.row[id] {
		scroll-margin-top: 8.75rem;
	}
}
/* #endregion anchor scroll offsets */
