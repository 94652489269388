.sub-navigation {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
}

.sub-navigation__item:not(:last-child) {
	margin-right: var(--space-30--rem);
}

.sub-navigation__item--current .sub-navigation__link {
	text-decoration: underline;
}

.sub-navigation__link {
	color: var(--color-white);
	text-decoration: none;
}
