.hero-program {
	margin-block-start: var(--space-25--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.hero-program {
		margin-block-start: var(--space-30--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.hero-program {
		margin-block-start: var(--space-60--rem);
	}
}

.hero-program__top {
	display: flex;
	flex-direction: column;
}

.hero-program__inner {
	display: grid;
	grid-row-gap: var(--space-20--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.hero-program__inner {
		grid-column-gap: var(--space-40--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.hero-program__inner {
		grid-column-gap: var(--space-60--px);
	}
}

.hero-program__title {
	margin-block-end: var(--space-10--rem);
	order: 2;
}

.hero-program__terms {
	margin-block-end: var(--space-10--rem);
}

.hero-program__term {
	order: 1;
	text-transform: uppercase;
}

.hero-program__date {
	color: var(--color-red);
	margin-block-start: 0;
	margin-block-end: var(--space-20--rem);
	order: 3;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.hero-program__date {
		margin-block-end: var(--space-30--rem);
	}
}

.hero-program__actions {
	order: 4;
}

.hero-program__links {
	display: grid;
	grid-row-gap: 0.3125rem;
}

/* from-xsmall,min-width-560px and max-width-767px */
@media (min-width: 35rem) and (max-width: 47.9375rem) {
	.hero-program__links {
		grid-column-gap: var(--space-20--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.hero-program__links {
		margin-block-end: var(--space-20--rem);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.hero-program__links {
		grid-column-gap: var(--space-20--px);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.hero-program__links {
		grid-row-gap: var(--space-10--rem);
		grid-column-gap: var(--space-30--px);
	}
}

.hero-program__links .arrow-link {
	color: var(--color-purple);
}

.hero-program__links .arrow-link .icon-arrow-right {
	fill: var(--color-purple);
}

.hero-program__cta {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.hero-program__cta {
		margin-block-start: var(--space-20--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.hero-program__cta {
		margin-block-start: var(--space-30--rem);
	}
}

.hero-program__intro {
	border-top: 1px solid var(--color-grey-light);
	border-bottom: 1px solid var(--color-grey-light);
	color: var(--color-black);
	margin-block-start: var(--space-25--rem);
	padding-block: var(--space-30--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.hero-program__intro {
		margin-block-start: var(--space-30--rem);
		padding-block: var(--space-60--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.hero-program__intro {
		margin-block-start: var(--space-60--rem);
	}
}

.hero-program__image__source {
	width: 100%;
}
