.page-hero {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.page-hero {
		border-bottom: var(--space-1--px) solid var(--color-grey-light);
		display: flex;
	}
}

.page-hero__column {
	border-bottom: var(--space-1--px) solid var(--color-grey-light);
	box-sizing: border-box;
	padding: var(--space-30--rem) 0;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.page-hero__column {
		border-bottom: 0;
		padding: var(--space-70--rem) var(--space-70--px);
		width: 50%;
	}

	.page-hero__column:first-child {
		border-right: var(--space-1--px) solid var(--color-grey-light);
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.page-hero__column {
		padding: var(--space-90--rem) var(--space-90--px);
	}
}

.page-hero__column__title {
	text-transform: uppercase;
}
