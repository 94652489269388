.list-header {
	margin-bottom: var(--space-20--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.list-header {
		display: flex;
		margin-bottom: var(--space-30--rem);
		justify-content: space-between;
		align-items: flex-end;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.list-header {
		margin-bottom: var(--space-40--rem);
	}
}

.list-header__title {
	margin: 0;
	text-transform: uppercase;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.list-header__title {
		flex-grow: 1;
		flex-shrink: 1;
	}
}

/* up-to-small,max-width-767px */
@media (max-width: 47.9375rem) {
	.list-header__link {
		margin-top: var(--space-10--px);
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.list-header__link {
		flex-shrink: 0;
		margin-left: var(--space-10--px);
		max-width: 50%;
	}
}
