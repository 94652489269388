.image {
	margin: 0;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.image {
		margin: 0 auto;
		max-width: 40.625rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.image {
		max-width: 50rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.l-single[data-has-sidebar-navigation] .image {
		max-width: 100%;
	}
}

.image__source {
	width: 100%;
}

.image__caption {
	color: var(--color-grey-dark);
	margin-top: var(--space-10--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.image__caption {
		margin-top: var(--space-20--rem);
	}
}
