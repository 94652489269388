/* 400 */
@font-face {
	font-family: "F Grotesk";
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/f_grotest_muhka/FGroteskBook-Regular.woff2") format("woff2");
}

@font-face {
	font-family: "F Grotesk";
	font-style: italic;
	font-weight: 400;
	src: url("../fonts/f_grotest_muhka/FGroteskBook-Italic.woff2") format("woff2");
}

/* 700 */
@font-face {
	font-family: "F Grotesk";
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/f_grotest_muhka/FGroteskBold-Regular.woff2") format("woff2");
}

@font-face {
	font-family: "F Grotesk";
	font-style: italic;
	font-weight: 700;
	src: url("../fonts/f_grotest_muhka/FGroteskBold-Italic.woff2") format("woff2");
}
