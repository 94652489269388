.teaser-list-c {
	display: grid;
	grid-row-gap: var(--space-40--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		grid-column-gap: var(--column-gap);
		grid-template-columns: repeat(2, 1fr);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		grid-row-gap: var(--space-60--rem);
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		grid-row-gap: var(--space-90--rem);
	}
}

.teaser-list-c__teaser {
	color: var(--color-black);
	text-decoration: none;
}

/* #region Image */
.teaser-list-c__image-container {
	display: block;
	margin-bottom: var(--space-20--rem);
	position: relative;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list-c__image-container {
		margin-bottom: var(--space-40--rem);
	}
}

.teaser-list-c__image-container .icon-play {
	position: absolute;
	stroke: var(--color-white);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
}

.teaser-list-c__image {
	width: 100%;
}
/* #endregion Image */

/* #region Meta */
.teaser-list-c__meta {
}

.teaser-list-c__meta__title,
.teaser-list-c__meta__date,
.teaser-list-c__meta__subtitle {
	margin: 0;
}

.teaser-list-c__meta__subtitle {
	margin-bottom: var(--space-10--rem);
	text-transform: uppercase;
}

.teaser-list-c__meta__title {
	color: var(--color-purple);
	text-transform: none;
}

.teaser-list-c__meta-container {
	display: flex;
	flex-flow: column;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.teaser-list-c__meta-container {
		flex-flow: row;
	}
}

.teaser-list-c__meta__date {
	color: var(--color-red);
	margin-right: var(--space-10--px);
}

.teaser-list-c__meta__duration {
	color: var(--color-green);
	margin: 0;
}
/* #endregion Meta */

/* #region Excerpt */
.teaser-list-c__excerpt {
	margin-top: var(--space-20--rem);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list-c__excerpt {
		margin-top: var(--space-40--rem);
	}
}
/* #endregion Excerpt */

/* #region Author */
.teaser-list-c__author {
	color: var(--color-grey-dark);
	margin-top: var(--space-10--rem);
}
/* #endregion Author */
