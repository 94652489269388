.meta-navigation {
	display: flex;
	flex-direction: row;
	list-style: none;
	margin: 0;
	padding: 0;
}

.meta-navigation__item:not(:last-child) {
	margin-right: var(--space-20--px);
}

.meta-navigation__item--current .meta-navigation__link {
	color: var(--color-black);
}

.meta-navigation__link {
	color: var(--color-grey-dark);
	text-decoration: none;
}

.meta-navigation__link:hover {
	color: var(--color-black);
}
