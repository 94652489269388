.banner {
	display: block;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.banner {
		position: relative;
	}
}

/* #region Image */
.banner__image-container {
	aspect-ratio: 3 / 1;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.banner__image-container {
		aspect-ratio: 5 / 1;
	}
}

.banner__image {
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.banner__image {
		object-fit: cover;
		height: 100%;
	}
}
/* #endregion Image */

/* #region Title */
.banner__title {
	background-color: var(--hover-color);
	color: var(--color-white);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.banner__title {
		background-color: transparent;
		position: absolute;
		top: 0;
		left: 0;
		transition: background-color 128ms ease-in;
		width: 100%;
		height: 100%;
	}
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.banner__title:hover {
		background-color: var(--hover-color-opacity);
	}
}

.banner__title h2 {
	display: inline-block;
	padding: var(--space-15--rem) var(--space-20--px);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.banner__title h2 {
		padding: var(--space-10--rem) var(--space-20--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.banner__title h2 {
		padding: var(--space-30--rem) var(--space-40--px);
	}
}
/* #endregion Title */
