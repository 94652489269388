.deadline-label {
	display: none;
	font-size: 0.7rem;
	font-weight: 600;
	line-height: 2.5; /* 28px */
	line-height: 1.75rem;
	padding-inline: var(--space-8--px);
	justify-content: center;
	align-items: center;
	pointer-events: none;
	position: absolute;
	text-transform: uppercase;
	bottom: 0;
	left: 0;
	z-index: 1;
}

[data-deadline-passed] .deadline-label {
	background-color: var(--color-purple);
	color: white;
	display: flex;
}

[data-deadline-soon] .deadline-label {
	background-color: var(--color-red);
	color: white;
	display: flex;
}
