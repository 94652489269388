.blockquote {
	box-sizing: border-box;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.blockquote {
		margin: 0 auto;
		padding-inline-start: var(--space-30--px);
		padding-inline-end: var(--space-60--px);
		max-width: 40.625rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.blockquote {
		max-width: 50rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.l-single[data-has-sidebar-navigation] .blockquote {
		margin-inline-start: 0;
	}
}
