.action-blocks {
	box-sizing: border-box;
	margin: 0 auto;
	overflow-x: auto;
	width: 100%;
	max-width: 83.75rem;
}

.action-blocks__inner {
	display: grid;
	grid-column-gap: var(--column-gap);
	grid-template-columns: repeat(4, 1fr);
	overflow-x: auto;
	overflow-y: hidden;
	padding: 0 var(--space-20--px);
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.action-blocks__inner {
		padding: 0;
	}
}

.action-blocks__item {
	position: relative;
	min-width: 9.375rem;
}

.action-blocks__item__image {
	width: 100%;
}

.action-blocks__item__text {
	background-color: var(--color-black-10);
	color: var(--color-white);
	margin: 0;
	padding: 0.25rem 7px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: background-color 128ms linear;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.action-blocks__item__text {
		padding: var(--space-10--rem) var(--space-15--px);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.action-blocks__item__text {
		padding: var(--space-15--rem) var(--space-20--px);
	}
}

.action-blocks__item:hover .action-blocks__item__text {
	background-color: var(--color-black-40);
	text-decoration: underline;
}
