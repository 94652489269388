.teaser-list-b {
	display: grid;
	grid-row-gap: var(--space-10--rem);
	grid-template-rows: auto;
}

/* from-xsmall,min-width-560px */
@media (min-width: 35rem) {
	.teaser-list-b {
		grid-row-gap: var(--space-20--rem);
		grid-column-gap: var(--space-20--px);
		grid-template-columns: repeat(2, 1fr);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list-b {
		grid-row-gap: var(--space-40--rem);
		grid-column-gap: var(--space-40--px);
	}
}

.teaser-list-b__item__link {
	display: block;
	position: relative;
}

.teaser-list-b__item__link:hover .teaser-list-b__item__image-overlay,
.teaser-list-b__item__link:hover .teaser-list-b__item__color-container {
	background-color: var(--hover-color-opacity);
}

/* #region Image */
.teaser-list-b__item__image-container {
	aspect-ratio: 3 / 2;
	background-color: var(--hover-color);
	transition: background-color 128ms ease-in;
}

.teaser-list-b__item__image-container:has(picture) {
	background-color: transparent;
}

.teaser-list-b__item__image-overlay {
	background-color: var(--color-black-20);
	position: absolute;
	top: 0;
	left: 0;
	transition: background-color 128ms ease-in;
	width: 100%;
	height: 100%;
}

.teaser-list-b__item__image {
	width: 100%;
	height: auto;
}

@supports (object-fit: cover) {
	.teaser-list-b__item__image {
		object-fit: cover;
		height: 100%;
	}
}

/* #endregion Image */

/* #region Title */
.teaser-list-b__item__title {
	color: var(--color-white);
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: background-color 128ms ease-in;
}

.teaser-list-b__item__title p {
	padding: 0.875rem var(--space-20--px);
	text-transform: none;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.teaser-list-b__item__title p {
		padding: var(--space-15--rem) var(--space-20--px);
	}
}
/* #endregion Title */
