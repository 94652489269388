.wysiwyg {
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.wysiwyg {
		margin: 0 auto;
		max-width: 40.625rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.wysiwyg {
		max-width: 50rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.l-single[data-has-sidebar-navigation] .wysiwyg {
		margin-left: 0;
	}
}
