.ensembles-actor-modal-dialog {
	z-index: 99999;
}

.modal-dialog[data-state] .ensembles-actor-modal-dialog__content {
	top: 50px;
	transform: translateX(-50%);
	width: 100%;
	max-height: calc(100vh - 50px);
	max-height: calc(100svh - 50px);

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		max-width: 85vw;
		max-height: calc(100vh - (90px * 2));
		max-height: calc(100svh - (90px * 2));
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		max-width: 988px;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		max-width: 1110px;
	}
}

.ensembles-actor-modal-dialog__header {
	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.ensembles-actor-modal-dialog__header__meta {
	margin-block: var(--space-60--rem);
	text-align: center;

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		padding-inline: 84px;
		text-align: left;
		width: 50%;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		padding-inline: 120px;
	}
}

.ensembles-actor-modal-dialog__label {
	font-size: 1.5rem;
	margin-bottom: 1rem;

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		font-size: 2rem;
	}
}

.ensembles-actor-modal-dialog__date-of-birth::before {
	content: "° ";
}

.ensembles-actor-modal-dialog__date-of-death::before {
	content: "† ";
	margin-inline-start: var(--space-10--px);
}

.ensembles-actor-modal-dialog__image {
	object-fit: cover;
	width: 100%;

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		min-height: 300px;
	}
}

.ensembles-actor-modal-dialog__header__image-container {
	position: relative;

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		width: 100%;
		max-width: 50%;
	}
}

.ensembles-actor-modal-dialog__poster-credits {
	color: var(--color-white);
	position: absolute;
	text-align: right;
	right: 20px;
	bottom: 20px;
	left: 20px;
}

.ensembles-actor-modal-dialog__anchor-links {
	border-top: 1px solid var(--color-grey-light);
	border-bottom: 1px solid var(--color-grey-light);
	font-size: 1rem;
	padding-inline: var(--space-16--px);
	padding-block: var(--space-16--rem);

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		padding-inline-start: 84px;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		padding-inline-start: 120px;
	}
}

.ensembles-actor-modal-dialog__anchor-link {
	color: currentColor;
	text-decoration: none;
}

.ensembles-actor-modal-dialog__anchor-link:last-child {
	padding-inline-start: var(--space-16--px);
}

.ensembles-actor-modal-dialog__anchor-link:first-child::after {
	border-right: 1px solid var(--color-grey-light);
	content: "";
	padding-inline-end: var(--space-16--px);
}

.ensembles-actor-modal-dialog__info {
	box-sizing: border-box;
	padding-inline: var(--space-16--px);
	padding-block: var(--space-16--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		padding-inline: var(--space-40--px);
		padding-block: var(--space-40--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		margin: 0 auto;
		padding-inline: 0;
		padding-block: var(--space-60--rem);
		width: 652px;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		width: 880px;
	}
}

.ensembles-actor-modal-dialog__list {
	display: grid;
	list-style-type: none;
	margin: 0;
	padding: 0;
	scroll-margin-top: var(--space-40--rem);

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		grid-template-columns: repeat(2, 1fr);
	}
}

.ensembles-actor-modal-dialog__list__link {
	color: currentColor;
	text-decoration: none;
	transition: background-color 128ms ease-in-out;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		display: flex;
		align-items: center;
	}
}

.ensembles-actor-modal-dialog__list__item {
	position: relative;
}

.ensembles-actor-modal-dialog__list__item::after {
	border: 0 solid var(--color-purple);
	content: "";
	display: block;
	pointer-events: none;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: border 128ms ease-in-out;
}

.ensembles-actor-modal-dialog__list__item:hover::after {
	border-width: 4px;
}

.ensembles-actor-modal-dialog__list__item:hover .ensembles-actor-modal-dialog__list__link {
	background-color: #f1f1f1;
}

.ensembles-actor-modal-dialog__list__item:nth-child(even) .ensembles-actor-modal-dialog__list__link {
	/* up-to-medium,max-width-1023px */
	@media (max-width: 63.9375rem) {
		flex-direction: row-reverse;
	}
}

.ensembles-actor-modal-dialog__list__item:nth-child(4n + 1) .ensembles-actor-modal-dialog__list__link,
.ensembles-actor-modal-dialog__list__item:nth-child(4n + 2) .ensembles-actor-modal-dialog__list__link {
	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		flex-direction: row-reverse;
	}
}

.ensembles-actor-modal-dialog__list__link img {
	width: 100%;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		width: 50%;
	}
}

.ensembles-actor-modal-dialog__list__content {
	text-align: center;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		width: 50%;
	}
}

.ensembles-actor-modal-dialog__list__link__date-begin {
	color: var(--color-red);
}

.ensembles-actor-modal-dialog__list__title {
	margin: 0;
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
	text-align: center;
	text-transform: initial;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		padding-block: 0;
	}
}

.ensembles-actor-modal-dialog__more-link-container {
	display: flex;
	padding-inline: var(--space-16--px);
	padding-block: var(--space-40--rem);
	justify-content: center;

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		padding-inline: var(--space-40--px);
		padding-block: var(--space-60--rem);
	}
}

.ensembles-actor-modal-dialog__bg__close {
	background-color: var(--color-purple-80);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.ensembles-actor-modal-dialog__bg__close button {
	-mrh-resets: button;
	color: var(--color-white);
	position: absolute;
	text-align: center;
	text-transform: initial;
	top: 0;
	right: 0;
	left: 0;
	height: 50px;

	@media (min-width: 35rem) {
		height: 90px;
	}
}
