.page-jumps {
	border-bottom: var(--space-1--px) solid var(--color-grey-light);
	margin: 0;
	padding-inline: 0;
	padding-block: var(--space-8--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.page-jumps {
		padding-block: var(--space-16--rem);
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.page-jumps {
		padding-block: var(--space-20--rem);
	}
}

.page-jumps__list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: center;
	position: relative;
}

.page-jumps__list__item {
	display: flex;
	margin: var(--space-8--rem) var(--space-16--px);
	align-items: center;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.page-jumps__list__item {
		margin: 0;
	}

	.page-jumps__list__item:not(:last-child)::after {
		background-color: var(--color-grey-light);
		content: "";
		display: inline-block;
		margin-inline: var(--space-8--px);
		width: 0.375rem;
		height: var(--space-1--rem);
	}
}

.page-jumps__list__item :any-link {
	color: var(--color-grey-light);
	text-decoration: none;
	text-transform: capitalize;
}
