.actors-list {
	display: grid;
	grid-row-gap: var(--space-10--rem);
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		grid-row-gap: 0.75rem;
		grid-column-gap: 12px;
		grid-template-rows: minmax(0, max-content);
		grid-template-columns: repeat(2, 243px);
	}

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		grid-row-gap: var(--space-20--rem);
		grid-column-gap: var(--space-20--px);
		grid-template-columns: repeat(2, 340px);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		grid-template-columns: repeat(2, 484px);
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		grid-row-gap: var(--space-40--rem);
		grid-column-gap: var(--space-40--px);
		grid-template-columns: repeat(3, 419px);
	}
}

.actors-list__card {
	/* up-to-xsmall,max-width-559px */
	@media (max-width: 34.9375rem) {
		max-width: 302px;
	}
}

.actors-list__card > .teaser-z {
	box-sizing: border-box;
	height: 100%;
}
