.hero-archive {
	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.hero-archive__header {
	margin-block-end: var(--space-25--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		margin-block-end: 0;
		width: calc(50% - calc(var(--column-gap) / 2));
	}
}

.hero-archive__title {
	color: var(--color-grey-dark);
}

.hero-archive__filter {
	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		display: grid;
		column-gap: var(--column-gap);
		grid-template-columns: repeat(2, 1fr);
	}

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		width: calc(50% - calc(var(--column-gap) / 2));
	}
}
