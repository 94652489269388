.arrow-link {
	color: var(--color-grey-dark);
	display: flex;
	align-items: center;
	text-decoration: none;
	transition: color 128ms ease-out;
}

.arrow-link:hover {
	color: var(--color-black);
}

/* #region Icon */
.arrow-link .icon-arrow-right {
	fill: var(--color-grey-dark);
	flex-shrink: 0;
	margin-right: var(--space-10--px);
	width: 0.3125rem;
	height: 0.5rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.arrow-link .icon-arrow-right {
		width: 0.375rem;
		height: 0.625rem;
	}
}

.arrow-link:hover .icon-arrow-right {
	fill: var(--color-black);
}
/* #endregion Icon */
