:root {
	/* #region Colors */
	--color-foreground: var(--color-black);
	--color-background: var(--color-white);
	--color-black: rgb(24 24 24);
	--color-black-10: rgb(24 24 24 / 0.1);
	--color-black-20: rgb(24 24 24 / 0.2);
	--color-black-30: rgb(24 24 24 / 0.3);
	--color-black-40: rgb(24 24 24 / 0.4);
	--color-white: rgb(255 255 255);
	--color-green: rgb(26 185 50);
	--color-green-80: rgb(26 185 50/ 0.8);
	--color-purple: rgb(71 0 255);
	--color-purple-80: rgb(71 0 255 / 0.8);
	--color-red: rgb(243 43 44);
	--color-red-80: rgb(243 43 44 / 0.8);
	--color-grey-dark: rgb(180 180 180);
	--color-grey-light: rgb(215 215 215);
	--color-grey-super-light: rgb(235 235 235);
	/* #endregion Colors */

	/* #region Typography */
	--font-sans: "F Grotesk", sans-serif;
	/* #endregion Typography */

	/* #region Site header */
	--meta-navigation-height: 2.75rem; /* This is the height of the meta-navigation bar */
	--scrolled-header-height: 3.125rem; /* Height of site header when scrolled */
	/* #endregion Site header */

	/* #region Z-indices */
	--z-index-header: 100;
	/* #endregion Z-indices */

	/* #region Spacers */
	--space-1--px: 1px;
	--space-1--rem: 0.0625rem;
	--space-8--px: 8px;
	--space-8--rem: 0.5rem;
	--space-10--px: 10px;
	--space-10--rem: 0.625rem;
	--space-15--px: 15px;
	--space-15--rem: 0.9375rem;
	--space-16--px: 16px;
	--space-16--rem: 1rem;
	--space-20--px: 20px;
	--space-20--rem: 1.25rem;
	--space-25--px: 25px;
	--space-25--rem: 1.5625rem;
	--space-30--px: 30px;
	--space-30--rem: 1.875rem;
	--space-40--px: 40px;
	--space-40--rem: 2.5rem;
	--space-50--px: 50px;
	--space-50--rem: 3.125rem;
	--space-60--px: 60px;
	--space-60--rem: 3.75rem;
	--space-70--px: 70px;
	--space-70--rem: 4.375rem;
	--space-90--px: 90px;
	--space-90--rem: 5.625rem;
	--space-100--px: 100px;
	--space-100--rem: 6.25rem;
	--space-150--px: 150px;
	--space-150--rem: 9.375rem;
	/* #endregion Spacers */

	/* #region Columns */
	--column-gap: 25px;
	/* #endregion  */
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	:root {
		--column-gap: 30px;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	:root {
		/* #region Site header */
		--scrolled-header-height: 4.375rem; /* Height of site header when scrolled */
		/* #endregion Site header */

		/* #region Columns */
		--column-gap: 40px;
		/* #endregion Columns */
	}
}

/* #region Section relative properties */
:root {
	--hover-color: var(--color-purple);
	--hover-color-opacity: var(--color-purple-80);
}

.site-section--is-collections {
	--hover-color: var(--color-green);
	--hover-color-opacity: var(--color-green-80);
}

.site-section--is-programme {
	--hover-color: var(--color-purple);
	--hover-color-opacity: var(--color-purple-80);
}

.site-section--is-research {
	--hover-color: var(--color-red);
	--hover-color-opacity: var(--color-red-80);
}
/* #endregion Section relative properties */
