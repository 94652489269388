.logo-grid {
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.logo-grid {
		margin: 0 auto;
		max-width: 40.625rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.logo-grid {
		max-width: 50rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.l-single[data-has-sidebar-navigation] .logo-grid {
		max-width: 100%;
	}
}

.logo-grid__list {
	box-sizing: border-box;
	display: grid;
	grid-row-gap: var(--space-20--rem);
	grid-column-gap: var(--space-20--px);
	grid-template-columns: repeat(auto-fill, 7.8125rem);
	list-style: none;
	margin: 0;
	padding: 0;
}

/* from-large,min-width-1440px */
@media (width >= 90rem) {
	.logo-grid__list {
		grid-row-gap: var(--space-30--rem);
		grid-column-gap: var(--space-30--px);
	}
}

.logo-grid__list__item {
	width: 7.8125rem;
}

.logo-grid__list__image {
	width: 100%;
}
