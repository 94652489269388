.navigation-overlay {
	background-color: var(--color-background);
	content-visibility: hidden;
	display: none;
	opacity: 0;
	outline: none;
	overflow-y: auto;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}

.navigation-overlay[data-state="opening"],
.navigation-overlay[data-state="open"],
.navigation-overlay[data-state="closing"] {
	content-visibility: visible;
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.navigation-overlay .site-header__icon:last-child {
	display: block;
}

.navigation-overlay__info {
	background-color: var(--color-grey-super-light);
	padding: var(--space-10--rem) var(--space-20--px);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.navigation-overlay__navigation {
		margin: 0 auto;
		padding: 0 var(--space-20--px);
		max-width: 83.75rem;
	}
}

/* #region Primary Navigation */
.navigation-overlay__primary-navigation__list {
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation-overlay__primary-navigation__item {
	border-bottom: var(--space-1--px) solid var(--color-grey-dark);
	padding: 0.75rem 0;
}

.navigation-overlay__primary-navigation__item--current .navigation-overlay__primary-navigation__link {
	color: var(--hover-color);
}

.navigation-overlay__primary-navigation__link {
	color: var(--color-black);
	padding: 0 var(--space-20--px);
	text-decoration: none;
}
/* #endregion Primary Navigation */

/* #region Sub Navigation */
.navigation-overlay__sub-navigation {
	margin-top: 0.75rem;
}

.navigation-overlay__sub-navigation__list {
	border-top: var(--space-1--px) solid var(--color-grey-dark);
	display: flex;
	flex-flow: column;
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation-overlay__sub-navigation__item {
	padding: 0.75rem var(--space-40--px);
}

.navigation-overlay__sub-navigation__item:not(:last-child) {
	border-bottom: var(--space-1--px) solid var(--color-grey-dark);
}

.navigation-overlay__sub-navigation__item:last-child {
	padding-bottom: 0;
}

.navigation-overlay__sub-navigation__item--current .navigation-overlay__sub-navigation__link {
	color: var(--hover-color);
}

.navigation-overlay__sub-navigation__link {
	color: var(--color-grey-dark);
	text-decoration: none;
}
/* #endregion Sub Navigation */

/* #region Meta Navigation */
.navigation-overlay__meta-navigation__list {
	display: flex;
	flex-flow: column;
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation-overlay__meta-navigation__item {
	background-color: var(--color-grey-super-light);
	border-bottom: var(--space-1--px) solid var(--color-grey-dark);
	color: var(--color-grey-dark);
	padding: 0.75rem 0;
}

.navigation-overlay__meta-navigation__item .meta-navigation__link {
	padding: 0 var(--space-20--px);
}

.navigation-overlay__meta-navigation__item--current .navigation-overlay__meta-navigation__link {
	color: var(--color-black);
}

.navigation-overlay__meta-navigation__link {
	color: var(--color-grey-dark);
	padding: 0 var(--space-20--px);
	text-decoration: none;
}

.navigation-overlay__meta-navigation__link:hover {
	color: var(--color-black);
}

/* #endregion Meta Navigation */

/* #region Language */
.navigation-overlay__language-switch {
	display: flex;
	list-style: none;
	margin: 0;
	padding: var(--space-10--px) var(--space-20--px);
	transition: max-width 128ms ease-in;
}

.navigation-overlay__language-switch__item:not(:last-child) {
	margin-right: var(--space-15--px);
}

.navigation-overlay__language-switch__link {
	border-bottom: 0 solid transparent;
	color: var(--color-grey-dark);
	display: block;
	text-decoration: none;
	text-transform: uppercase;
}

.navigation-overlay__language-switch__link--current {
	color: var(--color-black);
}

.navigation-overlay__language-switch__link:hover,
.navigation-overlay__language-switch__link:focus {
	color: var(--color-black);
}
/* #endregion Language */

/* #region Styleguide */
.l-styleguide ~ .navigation-overlay {
	z-index: 99999;
}
/* #endregion Styleguide */
