.l-single {
}

.l-single__breadcrumb {
	padding-block-start: var(--space-25--rem);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.l-single__breadcrumb {
		display: none;
	}
}

.l-single__main {
}

.l-single__main--grid {
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.l-single__main--grid {
		display: grid;
		grid-column-gap: var(--column-gap);
		grid-template-areas: "sidebar content content content";
		grid-template-columns: repeat(4, 1fr);
	}
}

.l-single__main__sidebar {
	display: none;
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.l-single__main__sidebar {
		display: block;
		grid-area: sidebar;
		align-self: start;
		position: sticky;
		top: var(--scrolled-header-height); /* height of siteheader (scrolled) */
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.l-single__main__sidebar {
		top: 8.125rem; /* height of siteheader (scrolled) + sub navigation */
	}

	.l-single--article .l-single__main__sidebar {
		top: var(--scrolled-header-height); /* height of siteheader (scrolled) */
	}
}

.l-single__main__content {
	grid-area: content;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.l-single__main__content__title {
		margin: 0 auto;
		max-width: 40.625rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.l-single__main__content__title {
		max-width: 50rem;
	}
}

.l-single__video .row {
	margin-bottom: 0;
}
