.info-panels {
	display: flex;
	flex-direction: column;

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		flex-direction: row;
		justify-content: space-between;
	}
}

.info-panels[data-panel-small-right] {
	flex-direction: column-reverse;

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		flex-direction: row-reverse;
	}
}

/* #region Small panel */
.info-panels__panel-small {
	background-color: var(--color-purple);
	color: var(--color-white);

	/* up-to-medium,max-width-1023px */
	@media (max-width: 63.9375rem) {
		margin-block-end: var(--space-25--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		width: calc((100% - (var(--column-gap) * 3)) / 4);
	}
}

.info-panels[data-panel-small-right] .info-panels__panel-small {
	/* up-to-medium,max-width-1023px */
	@media (max-width: 63.9375rem) {
		margin-block-start: var(--space-25--rem);
		margin-block-end: 0;
	}
}

.info-panels__panel-small a {
	display: block;
	text-decoration: none;
	height: 100%;
}

.info-panels__panel-small a:hover {
	color: var(--color-white);
}

.info-panels__panel-small__image {
	/* up-to-medium,max-width-1023px */
	@media (max-width: 63.9375rem) {
		display: none;
	}
}

.info-panels__panel-small__content {
	padding-inline: var(--space-20--px);
	padding-block: var(--space-20--rem);
}
/* #endregion Small panel */

/* #region Large Panel */
.info-panels__panel-large {
	background-color: var(--color-white);
	border-top: 1px solid var(--color-black);
	border-bottom: 1px solid var(--color-black);
	color: var(--color-black);

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		display: flex;
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		width: calc((((100% - (var(--column-gap) * 3)) / 4) * 3) + var(--column-gap) * 2);
	}
}

.info-panels__panel-large__content,
.info-panels__panel-large__image {
	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		width: 50%;
	}
}

.info-panels__panel-large__content {
	display: flex;
	align-items: center;
}

.info-panels__panel-large__content :is(h2, h3, h4, h5, h6, p) {
	margin-block: var(--space-15--rem);
}

.info-panels__panel-large__content :is(h2, h3, h4, h5, h6, p):first-child {
	margin-block-start: 0;
}

.info-panels__panel-large__content :is(h2, h3, h4, h5, h6, p):last-child {
	margin-block-end: 0;
}

.info-panels__panel-large__content__inner {
	padding-inline: var(--space-30--px);
	padding-block: var(--space-30--rem);
}

.info-panels__panel-large__title {
	text-transform: uppercase;
}
/* #endregion Large Panel */
