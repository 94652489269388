.video-text-50-50 {
	display: grid;
	grid-row-gap: var(--space-20--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		grid-column-gap: var(--column-gap);
		grid-template-columns: repeat(2, 1fr);
	}
}

.video-text-50-50[data-video-position-right] .video-text-50-50__video {
	order: 2;
}

.video-text-50-50__data__title,
.video-text-50-50__data__subtitle {
	margin: 0;
}

.video-text-50-50__data__title {
	color: var(--color-purple);
	text-transform: none;
}

.video-text-50-50__data__subtitle {
	text-transform: uppercase;
}

.video-text-50-50__data__meta {
	color: var(--color-red);
	margin: 0;
	margin-inline-end: var(--space-10--px);
}

.video-text-50-50__data__author {
	color: var(--color-grey-dark);
	margin-block-start: var(--space-10--rem);
	margin-block-end: 0;
}

.video-text-50-50__data__button {
	margin-block-start: var(--space-20--rem);

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		margin-block-start: var(--space-30--rem);
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		margin-block-start: var(--space-40--rem);
	}
}
