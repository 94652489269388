@media (prefers-reduced-motion: reduce) {
	*,
	*::after,
	*::before {
		animation-delay: -1ms !important;
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		transition-delay: -1ms !important;
		transition-duration: 0.01ms !important;
	}
}

/*
 * Give "display: none;" for elements with a "hidden" attribute specificity [0, 2, 0].
 * This will generally be higher than layout directives : "display: flex".
 * Because this is declared very early you can easily override it with ".foo[hidden]".
 */
[hidden][hidden] {
	display: none;
}

@supports (content-visibility: hidden) and (display: revert) {
	[hidden][hidden="until-found"] {
		content-visibility: hidden;
		display: revert;
	}
}

body {
	background-color: var(--color-background);
	box-sizing: border-box;
	color: var(--color-foreground);
	position: relative;
	text-underline-position: from-font;
	min-height: 100vh;
}

body.is-showing-modal-dialog {
	overflow: hidden;
}

textarea {
	resize: none;
}

input,
button,
textarea,
select {
	font: inherit;
}

hr {
	background-color: var(--color-grey-light);
	border: none;
	height: var(--space-1--rem);
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
}

table,
th,
td {
	border: 1px solid currentColor;
}

table {
	border-collapse: collapse;
	box-sizing: border-box;
	caption-side: bottom;
	margin: var(--space-8--rem) 0;
	width: 100%;
	max-width: 100%;
}

/*
 from-medium,tablet-l,min-width-1024px
 */
@media (min-width: 64rem) {
	table {
		margin: var(--space-16--rem) 0;
	}
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

.mr-table-scroll-container {
	overflow: auto;
}

th,
td {
	box-sizing: border-box;
	padding: var(--space-8--rem) var(--space-8--px);
	text-align: left;
}

/*
 from-medium,tablet-l,min-width-1024px
 */
@media (min-width: 64rem) {
	th,
	td {
		padding: var(--space-16--rem) var(--space-16--px);
	}
}

th {
	font-weight: 700;
}

table > caption {
	margin-top: var(--space-8--rem);
}

/*
 from-medium,tablet-l,min-width-1024px
 */
@media (min-width: 64rem) {
	table > caption {
		margin-top: var(--space-16--rem);
	}
}

:is(ol, ul):not([class]) {
	box-sizing: border-box;
	padding-left: 1.25rem;
}

li :is(ol, ul):not([class]) {
	margin-bottom: 0;
}

li:not([class]) {
	margin: 0;
	padding: 0 0 0.25rem 0;
	position: relative;
}

a:not([class]) {
	color: currentColor;
	display: inline;
	text-decoration: underline;
}

a:not([class]):is(:active, :focus, :hover) {
	color: var(--hover-color);
}

p a:not([class]) {
	line-height: inherit;
}

.wp-block-table {
	margin: 0; /* Remove margin from Figure tag set by Gutenberg */
}

pre {
	white-space: pre-wrap;
}
