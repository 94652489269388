.wrapper {
	box-sizing: border-box;
	margin: 0 auto;
	padding-right: var(--space-20--px);
	padding-left: var(--space-20--px);
	width: 100%;
	max-width: 83.75rem;
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.wrapper {
		padding-right: 0;
		padding-left: 0;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.wrapper--narrow {
		margin: 0 auto;
		max-width: 40.625rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.wrapper--narrow {
		max-width: 50rem;
	}
}
