.ensembles-event-modal-dialog {
	z-index: 99999;
}

.modal-dialog[data-state] .ensembles-event-modal-dialog__content {
	box-sizing: border-box;
	padding-block: var(--space-60--rem);
	top: 50px;
	transform: translateX(-50%);
	width: 100%;
	max-height: calc(100vh - 50px);
	max-height: calc(100svh - 50px);

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		max-width: 85vw;
		max-height: calc(100vh - (90px * 2));
		max-height: calc(100svh - (90px * 2));
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		max-width: 988px;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		max-width: 1110px;
	}
}

.ensembles-event-modal-dialog__header {
	margin: 0 auto;
	text-align: center;
	width: 82.5%;

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		max-width: 484px;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		max-width: 650px;
	}
}

.ensembles-event-modal-dialog__title {
	font-size: 1.5rem;
	line-height: 1.4;
	margin-block-end: var(--space-16--rem);

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		font-size: 2rem;
		margin-block-end: var(--space-60--rem);
		padding-block-start: var(--space-60--rem);
	}
}

/* #region Actors */
.ensembles-event-modal-dialog__actors {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	margin-block-end: var(--space-16--rem);
	padding: 0;
	justify-content: center;

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		margin-block-end: var(--space-60--rem);
	}
}

.ensembles-event-modal-dialog__actors :not(:last-child) {
	margin-inline-end: var(--space-8--rem);
}

.ensembles-event-modal-dialog__actors :not(:last-child)::after {
	content: ",";
}

.ensembles-event-modal-dialog__actor {
	flex-shrink: 0;
}

.ensembles-event-modal-dialog__actor__link {
	color: currentColor;
	font-size: 1rem;
}

.ensembles-event-modal-dialog__actor__link:hover {
	color: var(--color-green);
}
/* #endregion Actors */

.ensembles-event-modal-dialog__dimensions,
.ensembles-event-modal-dialog__date-begin,
.ensembles-event-modal-dialog__materials,
.ensembles-event-modal-dialog__collection {
	font-size: 1rem;
	line-height: 1.65;
	margin: 0;
}

/* #region Image */
.ensembles-event-modal-dialog__image-container {
	padding-block-start: var(--space-60--rem);
	position: relative;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		margin: 0 auto;
		width: 59%;
	}
}

.ensembles-event-modal-dialog__image-credits {
	color: var(--color-white);
	margin: 0;
	position: absolute;
	right: var(--space-10--px);
	bottom: var(--space-16--rem);
}
/* #endregion Image */

.ensembles-event-modal-dialog__description {
	padding-inline: var(--space-16--px);
	padding-block-start: var(--space-16--rem);

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		padding-inline: var(--space-40--px);
		padding-block-start: var(--space-30--rem);
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		margin: 0 auto;
		padding-block-start: var(--space-60--rem);
		max-width: 652px;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		max-width: 880px;
	}
}

.ensembles-event-modal-dialog__bg__close {
	background-color: var(--color-purple-80);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.ensembles-event-modal-dialog__bg__close button {
	-mrh-resets: button;
	color: var(--color-white);
	position: absolute;
	text-align: center;
	text-transform: initial;
	top: 0;
	right: 0;
	left: 0;
	height: 50px;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		height: 90px;
	}
}
