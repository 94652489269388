.business-card-grid {
}

/* #region List */
.business-card-grid__list {
	display: grid;
	grid-row-gap: var(--space-10--rem);
	list-style: none;
	margin: 0;
	padding: 0;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		grid-row-gap: var(--space-20--rem);
		grid-column-gap: var(--space-20--px);
		grid-template-rows: minmax(0, max-content);
		grid-template-columns: repeat(2, minmax(0, 50%));
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		grid-row-gap: var(--space-20--rem);
		grid-template-columns: repeat(3, minmax(0, calc(100% / 3)));
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		grid-row-gap: var(--space-40--rem);
		grid-column-gap: var(--space-40--px);
	}
}
/* #endregion List */

/* #region Card */
.business-card-grid__card {
	background-color: var(--color-grey-super-light);
	box-sizing: border-box;
	display: flex;
	padding: var(--space-10--px) var(--space-10--px);
	align-items: center;
	height: 100%;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		display: block;
		padding: var(--space-15--px) var(--space-15--px);
		text-align: center;
	}

	/* from-medium,tablet-l,min-width-1024px */
	@media (min-width: 64rem) {
		padding: var(--space-20--px) var(--space-20--px);
	}
}

.business-card-grid__card__image {
	background-color: var(--color-white);
	border-radius: 999999px;
	display: block;
	flex-shrink: 0;
	margin-inline-end: var(--space-10--px);
	width: 4.375rem;
	height: 4.375rem;

	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		margin-inline: auto;
	}

	/* from-small,tablet-p,min-width-768px */
	@media (min-width: 48rem) {
		width: 5rem;
		height: 5rem;
	}

	/* from-large,min-width-1440px */
	@media (min-width: 90rem) {
		width: 5.625rem;
		height: 5.625rem;
	}
}

.business-card-grid__card__image__source {
	border-radius: 999999px;
	display: block;
	width: 100%;
}

.business-card-grid__card__info {
	/* from-xsmall,min-width-560px */
	@media (min-width: 35rem) {
		margin-block-start: var(--space-10--rem);
	}
}

.business-card-grid__card__info__name {
	font-weight: 700;
}

.business-card-grid__card__meta {
	margin-block-start: var(--space-10--rem);
}

.business-card-grid__card__meta__email {
	word-break: break-all;
}

.business-card-grid__card__meta__telephone {
	display: inline-block;
}
/* #endregion Card */
