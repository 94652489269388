.sidebar-navigation {
}

.sidebar-navigation__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.sidebar-navigation__link {
	color: var(--color-grey-dark);
	text-decoration: none;
}

.sidebar-navigation__link:hover {
	color: var(--hover-color);
}

.sidebar-navigation__item--current .sidebar-navigation__link {
	color: var(--hover-color);
}
