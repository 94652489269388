.image-2-columns {
	display: grid;
	row-gap: var(--space-25--rem);
	column-gap: var(--column-gap);
}

/* from-small,tablet-p,min-width-768px */
@media (min-width: 48rem) {
	.image-2-columns {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.image-2-columns {
		margin: 0 auto;
		max-width: 40.625rem;
	}
}

/* from-large,min-width-1440px */
@media (min-width: 90rem) {
	.image-2-columns {
		max-width: 50rem;
	}
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.l-single[data-has-sidebar-navigation] .image-2-columns {
		max-width: 100%;
	}
}

.image-2-columns__image {
	margin: 0;
}

.image-2-columns__image__source {
	width: 100%;
}

.image-2-columns__image__caption {
	color: var(--color-grey-dark);
	margin-top: var(--space-10--rem);
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.image-2-columns__image__caption {
		margin-top: var(--space-20--rem);
	}
}
